const SDK_SCRIPT_PATH =
  'https://flesanalysis.s3.ap-northeast-2.amazonaws.com/v0.2.4/bundle.js';

const sdkInit = () => {
  const accessKey = process.env.FLES_SDK_APP_KEY;
  const FA = new FlesAnalysis(accessKey);
  FA.start();
};

export { SDK_SCRIPT_PATH, sdkInit };
