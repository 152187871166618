import axios from 'axios';
import { getCookie, setCookies } from 'cookies-next';

const ACCESS_TOKEN = 'access_token';
const baseURL = process.env.APP_API_URL;
let token = getCookie(ACCESS_TOKEN) || null;

const instance = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const setLoginToken = (access_token: string) => {
  const newToken = access_token ? `Bearer ${access_token}` : '';
  token = newToken;
  setCookies(ACCESS_TOKEN, newToken);
  instance.defaults.headers.Authorization = newToken;
};

export { instance, ACCESS_TOKEN, token, setLoginToken };
