import { atom } from 'recoil';
import { UserT } from '@/types';

const loginState = atom({
  key: 'loginState',
  default: false,
});

const userInfoState = atom<UserT | null>({
  key: 'userInfoState',
  default: null,
});

export { loginState, userInfoState };
