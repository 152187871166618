import { useMutation } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { fetchUser } from '@/api/auth';
import { instance, setLoginToken } from '@/api';
import { loginState, userInfoState } from '@/stores/auth';

function useUser() {
  const setLoggedInState = useSetRecoilState(loginState);
  const setUserInfoState = useSetRecoilState(userInfoState);

  return useMutation('fetchUser', fetchUser, {
    retry: false,
    onSuccess: data => {
      const { id, email, name, is_admin } = data;
      setUserInfoState({ id, email, name, is_admin });
      setLoggedInState(true);
    },
    onError: () => {
      setLoginToken('');
      setLoggedInState(false);
      setUserInfoState(null);
    },
  });
}

export default useUser;
