import React, { useEffect } from 'react';
import Header from '@/components/Common/Header';
import Footer from '@/components/Common/Footer';
import useUser from '@/hooks/auth/useUser';
import { token } from '@/api';

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  const userMutate = useUser();

  useEffect(() => {
    if (token) userMutate.mutate();
  }, []);

  return (
    <>
      <Header />
      <main role="main" style={{ minWidth: '1260px' }}>
        {children}
      </main>
      <Footer />
    </>
  );
}

export default Layout;
