import { useRouter } from 'next/router';
import { useSetRecoilState } from 'recoil';
import { loginState, userInfoState } from '@/stores/auth';
import { setLoginToken } from '@/api';

function useLogout() {
  const router = useRouter();
  const setLogInState = useSetRecoilState(loginState);
  const setUserInfoState = useSetRecoilState(userInfoState);
  
  const handleLogout = () => {
    setLogInState(false);
    setUserInfoState(null);
    setLoginToken('');
    router.push('/');
  };

  return handleLogout;
}

export default useLogout;
