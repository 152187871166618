import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

const Ul = styled.ul`
  display: flex;
`;

const Li = styled.li`
  margin-right: 32px;

  a {
    font-size: ${({ theme }) => theme.fontSizes.base};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.palette.black};
  }
`;

function Nav() {
  const handleClickLink = (text: string) => {
    if (analytics) {
      analytics.track('product_clicked', {
        product_name: text,
        product_id: text,
      });
    }
  };

  return (
    <nav>
      <Ul>
        {NAV_MENUS.map(({ link, text }) => (
          <Li key={link} onClick={() => handleClickLink(text)} >
            <Link href={link} passHref>
              {text}
            </Link>
          </Li>
        ))}
      </Ul>
    </nav>
  );
}

export default Nav;

const NAV_MENUS = [
  {
    link: '/simulations',
    text: '밴픽 시뮬레이션',
  },
  {
    link: '/records',
    text: '기록실',
  },
  {
    link: '/records/rank',
    text: '랭킹',
  },
  {
    link: '/community',
    text: '커뮤니티',
  },
];
