import { instance } from '@/api';

interface ResetPassword {
  current_password: string;
  password: string;
  password_confirmation: string;
}

const fetchVerifyName = async (name: string) => {
  const { data } = await instance.get('/auth/duplicate', {
    params: { name },
  });
  return data;
};

const register = async (params: {
  email: string;
  code: string;
  password: string;
  name: string;
  password_confirmation: string;
}) => {
  const { data } = await instance.post('/auth/register', params);
  return data;
};

const login = async (params: { email: string; password: string }) => {
  const { data } = await instance.post('/auth/login', params);
  return data;
};

const fetchUser = async () => {
  const { data } = await instance.get('/auth/user');
  return data;
};

const fetchResetPassword = async ({
  current_password,
  password,
  password_confirmation,
}: ResetPassword) => {
  const { data } = await instance.post('/accounts/update', {
    current_password,
    password,
    password_confirmation,
  });

  return data;
};

const fetchVerifyStore = async (email: string) => {
  const { data } = await instance.post('/auth/verify/store', {
    email,
  });
  return data;
};

const fetchFind = async (email: string) => {
  const { data } = await instance.post('/auth/find', {
    email,
  });
  return data;
};

const fetchVerifyUpdate = async (email: string) => {
  const { data } = await instance.post('/auth/verify/update', {
    email,
  });
  return data;
};

const fetchVerifyEmail = async ({
  code,
  email,
}: {
  code: string;
  email: string;
}) => {
  const { data } = await instance.post('/auth/verify/mail', {
    code,
    email,
  });
  return data;
};

export {
  login,
  register,
  fetchUser,
  fetchFind,
  fetchVerifyName,
  fetchResetPassword,
  fetchVerifyStore,
  fetchVerifyUpdate,
  fetchVerifyEmail,
};

export type { ResetPassword };
