import { ReactElement, ReactNode } from 'react';
import { NextPage } from 'next';
import Script from 'next/script';
import type { AppProps } from 'next/app';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Layout from '@/components/Common/Layout';
import theme from '@/styles/theme';
import { GlobalStyle } from '@/styles/global';
import { SDK_SCRIPT_PATH, sdkInit } from '@/lib/sdk';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? (page => <Layout>{page}</Layout>);

  return (
    <>
      <Script
        id="fles-sdk"
        src={SDK_SCRIPT_PATH}
        strategy="lazyOnload"
        onLoad={() => sdkInit()}
      ></Script>

      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <DndProvider backend={HTML5Backend}>
              {getLayout(<Component {...pageProps} />)}
            </DndProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </>
  );
}

export default MyApp;
