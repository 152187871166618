import React from 'react';
import styled from 'styled-components';
import Logo from '@/components/Common/Logo/Logo';
import Nav from '@/components/Common/Header/Nav';
import NavUtil from '@/components/Common/Header/NavUtil';

const HeaderLayout = styled.header`
  background-color: ${({ theme }) => theme.palette.white};
`;

const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ theme }) => theme.deviceSizes.desktop};
  height: 80px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.palette.white};
`;

export default function Header() {
  return (
    <HeaderLayout>
      <HeaderBox>
        <Logo link="/" width={106} height={33} />
        <Nav />
        <NavUtil />
      </HeaderBox>
    </HeaderLayout>
  );
}
