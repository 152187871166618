import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import Link from 'next/link';
import { useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';
import { FaUser } from 'react-icons/fa';

import InputSearch from '@/components/Common/InputSearch';
import LocalLogo from '@/assets/icons/ico-flag.svg';

import { userInfoState } from '@/stores/auth';
import useLogout from '@/hooks/auth/useLogout';

const NavUtilLayout = styled.nav`
  display: flex;

  #headerSearchBar {
    padding: 0 0 0 12px;
    width: 100%;
    height: inherit;
    font-size: ${({ theme }) => theme.fontSizes.xsmall};

    &::placeholder {
      color: ${({ theme }) => theme.palette.lightGray};
    }
  }
`;
const Ul = styled.ul`
  display: flex;
  margin-left: 20px;
`;
const Li = styled.li`
  display: flex;
  align-items: center;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  &.admin {
    color: ${({ theme }) => theme.palette.primary};
  }

  &.accounts {
    a {
      color: ${({ theme }) => theme.palette.primary};
    }
  }
`;
const Item = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.lightGray};
  font-size: 13px;
  font-weight: 500;

  span {
    padding-left: 5px;
  }
`;
export default function NavUtil() {
  const router = useRouter();
  const handleLogout = useLogout();
  const userInfo = useRecoilValue(userInfoState);

  return (
    <NavUtilLayout>
      <InputSearch
        id="headerSearchBar"
        name="headerSearchBar"
        placeholder="소환사명을 입력하여 주세요."
        onSubmit={input => {
          router.replace(`/records/${input}`);
        }}
      />
      <Ul>
        {!userInfo && (
          <Li>
            <Link href="/login" passHref>
              <Item>LOGIN</Item>
            </Link>
          </Li>
        )}
        {userInfo && (
          <>
            {userInfo.is_admin === 1 && (
              <Li className="admin">
                <Link href="/admin">
                  <span>관리자</span>
                </Link>
              </Li>
            )}
            <Li className="accounts">
              <Link href="/accounts/simulations" passHref>
                <Item>
                  <FaUser />
                  <span>마이노트</span>
                </Item>
              </Link>
            </Li>
            <Li>
              <button type="button" onClick={handleLogout}>
                <Item>LOGOUT</Item>
              </button>
            </Li>
          </>
        )}
        {/** TODO
                 <Li>
                 <Link href="/#" passHref>
                 <Item>
                 <Image src={LocalLogo} alt="kor" width={24} height={24} />
                 <span>KOR</span>
                 </Item>
                 </Link>
                 </Li>
                 */}
      </Ul>
    </NavUtilLayout>
  );
}
