import Link from 'next/link';
import styled from 'styled-components';

import MailTo from '@/components/Common/MailTo/MailTo';

const FooterLayout = styled.footer`
  margin: 0 auto;
  padding: 60px 0 50px;
  width: ${({ theme }) => theme.deviceSizes.desktop};
  font-size: 13px;
`;

const FooterText = styled.p`
  color: ${({ theme }) => theme.palette.gray};
  line-height: 25px;
`;

const FooterNav = styled.nav`
  margin-bottom: 35px;
  ul {
    display: flex;
    align-items: center;

    li {
      margin-right: 15px;
      color: #999;
      display: flex;
      align-items: center;

      &:last-child {
        a::after {
          display: none;
        }
      }

      a {
        position: relative;
        display: block;
        line-height: 17px;
        text-decoration: underline;

        &::after {
          content: '|';
          display: inline-block;
          height: 10px;
          position: absolute;
          right: -9px;
        }
      }
    }
  }
`;

function Footer() {
  return (
    <FooterLayout>
      <FooterNav>
        <ul>
          {NAV_MENUS.map(({ link, text }) => (
            <li key={link + text}>
              <Link href={link}>{text}</Link>
            </li>
          ))}
          <li>
            <Link
              href={{
                pathname: '/cs/[slug]',
                query: { slug: 'ad' },
              }}
            >
              광고문의
            </Link>
          </li>
          <li>
            고객센터({' '}
            <Link
              href={{
                pathname: '/cs/[slug]',
                query: { slug: 'inquiries' },
              }}
            >
              1:1문의
            </Link>
            /
            <Link
              href={{
                pathname: '/cs/[slug]',
                query: { slug: 'faqs' },
              }}
            >
              FAQ
            </Link>
            )
          </li>
        </ul>
      </FooterNav>
      <FooterText>
        상호 : 주식회사 후에고 | 대표 : 조집 | 사업자등록번호 : 623-23-00350 | 통신판매업신고 : 2018-서울마포-0116 |
        주소 : 서울특별시 마포구 동교로25길 15, 2층
        <br />
        <MailTo email="business@juego11.com" /> <br/> Copyright © 후에고. All rights reserved.
      </FooterText>
    </FooterLayout>
  );
}

export default Footer;

const NAV_MENUS = [
  {
    link: '/community/notice',
    text: '공지사항',
  },
  {
    link: '/policy',
    text: '서비스 이용약관',
  },
  {
    link: '/policy/privacy',
    text: '개인정보 처리방침',
  },
];
